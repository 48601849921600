import React from "react";
import "./Admin_Refferal_details.css";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Web3 from "web3";
// import { API } from "../../../API";
import { CSVLink } from "react-csv";
import { Table } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { Api_LocalURl } from "../../Config";
// import 'dotenv/config'
import { ScaleLoader } from "react-spinners";
import CopyToClipboard from "react-copy-to-clipboard";
import {AiFillDelete} from 'react-icons/ai'
import { useSelector } from "react-redux";


export default function Admin_Refferal_details() {
  const [Refferal_data, setRefferal_data] = useState([]);
  const [refersh, setrefersh] = useState(false);
  const [getAddrea, setgetAddrea] = useState(null);
  const [WhiteListedAddresses, setWhiteListedAddresses] = useState([])
  const [Loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false);
  const [refAddress, setRefAddress] = useState("");
  const [Connect_wallet, setConnect_wallet] = useState("Connnect Wallet");
  const count = useSelector((state) => state.counter.data);

  const columns = [
    {
      label: "Refferal Address",
      key: "Refferal_Address",
    },
    {
      label: "Minter Address",
      key: "Minter_Address",
    },
    {
      label: "Mint Price",
      key: "Mint_Price",
    },
    {
      label: "Mint Value ",
      key: "Mint_Value",
    },
  ];


  useEffect(() => {
    // if (Connect_wallet.startsWith("0x")) {
    // } else {
    //     setRefAddress("Connect wallet");
    // }
    setRefAddress(`https://bookofgatesofficial.com/mint/?ref=${count.userName}`);
  }, [Connect_wallet]);
  const webSupply = new Web3("https://rpc.flashbots.net");
  console.log("webSupply", webSupply);

  const Get_refferal_info = async () => {
    const web3 = window.web3;
    try {
      let res = await axios.get(
        `${Api_LocalURl}Get_Refferal_data`
      );
      console.log("res", res);
      res = res.data.data;
      let arr = [];
      res.forEach((item, index) => {
        //    console.log("item",item);

        arr.push({
          Refferal_Address: item?.Refferal_Address,
          Minter_Address: item?.Minter_Address,
          Mint_Price: webSupply.utils.fromWei(item?.Mint_Price.toString()),
          Mint_Value: item?.Mint_Value,
        });
      });
      setRefferal_data(arr);
    } catch (error) {
      console.log(error);
    }
  };


  const get_All_White_Listed_Addres=async()=>{
    try {
      
      let res= await axios.get(`${Api_LocalURl}Get_All_WhiteList_Address`)
      if(res.data.success==true){
        setWhiteListedAddresses(res.data.data)
      }else{
        setWhiteListedAddresses(res.data.data)

      }

    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    Get_refferal_info();
    get_All_White_Listed_Addres()
  }, [refersh]);

  const Add_White_Listed_Address = async () => {
    try {
      if (getAddrea == null) {
        toast.error("Please Enter Addres First!");
      } else {
        setLoading(true)
        let res = await axios.post(`${Api_LocalURl}Add_WhiteList_Address`, {
          metamask_Address: getAddrea,
        });
        if(res.data.success==true){
          toast.success(res.data.msg)
          setgetAddrea("")
        setLoading(false)
        setrefersh(true)


        }else{
          toast.error(res.data.msg)
        setLoading(false)

        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false)

    }
  };


  const Delete_Address=async(address)=>{
    try {
      let res= await axios.get(`${Api_LocalURl}Delete_WhiteList_Address?metamask_Address=${address}`)
      console.log("Delet",res.data);
      if(res.data.success==true){
        toast.success(res.data.msg)
        setrefersh(true)
        
      }else{
        toast.error(res.data.msg)
      }
      
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <div className="mint_main">
        <div className="container">
        <div className="inner_reffer_div">
          <div className="refferal_copy">
            <h6>My Refferal Address</h6>
            <div className="d-flex">
              <input
                type="text"
                className="Refferal_input"
                value={refAddress}
              />
              <CopyToClipboard
                text={refAddress}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000);
                }}
              >
                <button>{copied ? "Copied!" : "Copy"}</button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
          <div className="play_header_main">
            <div className="container">

              <div className="play_header">
                <h1>Add WhiteList Address</h1>
              </div>
              <div className="Whit_list_div_main">
                <div className="inner_whitelist">
                  <label htmlFor="">Please Enter Address</label>
                  <input
                    type="text"
                    onChange={(e) => setgetAddrea(e.target.value)}
                    value={getAddrea}
                  />
                  <button onClick={()=>Add_White_Listed_Address()}>
                    {
                      Loading ? <>
                       <ScaleLoader color="#fff"  width={5} />
                      </>
                      :
                      "Add Address"
                    }

                    </button>
                </div>
              </div>
            </div>
          </div>
          <div className="play_header_main">
            <div className="container">
              <div className="play_header">
                <h1>All Refferal Details</h1>
              </div>
            </div>

            <div className="play_count_main ">
              <div className="container">
                <div
                  style={{ padding: "2rem" }}
                  className="tab_res table-responsive"
                >
                  <Table
                    bordered
                    className="text-white w-100 table-responsive "
                  >
                    <thead>
                      <tr>
                        {columns.map((items, index) => {
                          return (
                            <>
                              <th className="">{items.label}</th>
                            </>
                          );
                        })}
                      </tr>
                    </thead>

                    <tbody className="text-white">
                      {Refferal_data.map((items, index) => (
                        <tr key={index}>
                          {/* <th scope="row">{index + 1}</th> */}
                          <td>{items.Refferal_Address}</td>
                          <td>{items.Minter_Address}</td>
                          <td>{items.Mint_Price}</td>
                          <td>{items.Mint_Value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {/* <Button className="contBtn" > */}
                  <CSVLink
                    data={Refferal_data}
                    headers={columns}
                    filename={"Books_Of_Gates_Refferal.csv"}
                    className="text-decoration-none text-white contBtn"
                  >
                    Download File
                  </CSVLink>

                  {/* </Button> */}
                </div>
                {/* <div class="table-responsive">
              <table class="table  text-white">
                <thead>
                  <tr>
                    {
                      columns.map((items)=>{
                        <th>{items.label}</th>
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {Refferal_data.map((items, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{items.Refferal_Address}</td>
                          <td>{items.Minter_Address}</td>
                          <td>{items.Mint_Value}</td>
                          <td>{items.Mint_Value}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
              </div>
            </div>
          </div>


          <div className="play_header_main">
            <div className="container">
              <div className="play_header">
                <h1>White Listed Address</h1>
              </div>
            </div>

            <div className="play_count_main ">
              <div className="container">
                <div
                  style={{ padding: "2rem" }}
                  className="tab_res table-responsive"
                >
                  <Table
                    bordered
                    className="text-white w-100 table-responsive "
                  >
                    <thead>
                      <tr>
                      <th className="">Sr.No</th>
                      <th className="">White Listed Addresses</th>
                      <th className=""></th>


                      </tr>
                    </thead>

                    <tbody className="text-white">
                      {WhiteListedAddresses.map((items, index) => (
                        <tr key={index}>
                          {/* <th scope="row">{index + 1}</th> */}
                          <td>{index+1}</td>
                          <td>{items.metamask_Address}</td>
                          <td style={{cursor:"pointer"}} onClick={()=>Delete_Address(items.metamask_Address)}><AiFillDelete/></td>


                          
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                 

               
                </div>
               
              </div>
            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
}
