import React, { useEffect, useState } from "react";
import "./Refferal_page.css";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import axios from "axios";
import Web3 from "web3";
// import { API } from "../../../API";
import { CSVLink } from "react-csv";
import { Table } from "react-bootstrap";
import { Api_LocalURl } from "../../Config";

export default function Refferal_page() {
  const [refAddress, setRefAddress] = useState("");
  const [copied, setCopied] = useState(false);
  const [Address, setAddress] = useState("");
  const [Connect_wallet, setConnect_wallet] = useState("Connnect Wallet");
  const count = useSelector((state) => state.counter.data);
  // console.log("count", count);

  useEffect(() => {
    // if (Connect_wallet.startsWith("0x")) {
    // } else {
    //     setRefAddress("Connect wallet");
    // }
    setRefAddress(`https://bookofgatesofficial.com/mint/?ref=${count.userName}`);
  }, [Connect_wallet]);
  const [Refferal_data, setRefferal_data] = useState([]);
  const [Data, setData] = useState([]);

  const columns = [
    {
      label: "Refferal Address",
      key: "Refferal_Address",
    },
    {
      label: "Minter Address",
      key: "Minter_Address",
    },
    {
      label: "Mint Price",
      key: "Mint_Price",
    },
    {
      label: "Mint Value ",
      key: "Mint_Value",
    },
  ];

  const webSupply = new Web3("https://eth.llamarpc.com");
  console.log("webSupply", webSupply);

  const Get_refferal_info = async () => {
    const web3 = window.web3;
    try {
      let response = await axios.post(
        `${Api_LocalURl}Get_Refferal_Address`,
        {
          userName: count.userName,
        }
      );

      let res = await axios.get(
        `${Api_LocalURl}Refferal_Info?Address=${response.data.data}`
      );
      res = res.data.data;
      console.log("res", res);
      setData(res)
      let arr = [];
      res.forEach((item, index) => {
        //    console.log("item",item);

        arr.push({
          Refferal_Address: item?.Refferal_Address,
          Minter_Address: item?.Minter_Address,
          Mint_Price: webSupply.utils.fromWei(item?.Mint_Price.toString()),
          Mint_Value: item?.Mint_Value,
        });
      });
      setRefferal_data(arr);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Get_refferal_info();
  }, []);
  return (
    <div className="mint_main" style={{height:(Data?.length==0? "100vh":"auto")}}>
      <div className="container">
        <div className="inner_reffer_div">
          <div className="refferal_copy">
            <h6>My Refferal Address</h6>
            <div className="d-flex">
              <input
                type="text"
                className="Refferal_input"
                value={refAddress}
              />
              <CopyToClipboard
                text={refAddress}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000);
                }}
              >
                <button>{copied ? "Copied!" : "Copy"}</button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className="play_header_main">
          <div className="container">
            <div className="play_header">
              <h1>My Refferal Details</h1>
            </div>
          </div>

          <div className="play_count_main ">
            <div className="container">
              <div
                style={{ padding: "2rem" }}
                className="tab_res table-responsive"
              >
                <Table bordered className="text-white w-100 table-responsive ">
                  <thead>
                    <tr>
                      {columns.map((items, index) => {
                        return (
                          <>
                            <th className="">{items.label}</th>
                          </>
                        );
                      })}
                    </tr>
                  </thead>

                  <tbody className="text-white">
                    {Refferal_data.map((items, index) => (
                      <tr key={index}>
                        {/* <th scope="row">{index + 1}</th> */}
                        <td>{items.Refferal_Address}</td>
                        <td>{items.Minter_Address}</td>
                        <td>{items.Mint_Price}</td>
                        <td>{items.Mint_Value}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {/* <Button className="contBtn" > */}
                {Refferal_data.length == 0 ? (
                  <></>
                ) : (
                  <>
                    <CSVLink
                      data={Refferal_data}
                      headers={columns}
                      filename={"Books_Of_Gates_Refferal.csv"}
                    >
                      <button className="contBtn btn text-white">
                        {" "}
                        Download File
                      </button>
                    </CSVLink>
                  </>
                )}

                {/* </Button> */}
              </div>
              {/* <div class="table-responsive">
              <table class="table  text-white">
                <thead>
                  <tr>
                    {
                      columns.map((items)=>{
                        <th>{items.label}</th>
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {Refferal_data.map((items, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{items.Refferal_Address}</td>
                          <td>{items.Minter_Address}</td>
                          <td>{items.Mint_Value}</td>
                          <td>{items.Mint_Value}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
