import React, { useEffect, useState } from "react";
import "./Userauthentication.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { LogIndata, UserAuth, UserIsWhiteList } from "../../Redux/LogInSlice";
import { useNavigate } from "react-router-dom";
import { Api_LocalURl } from "../../Config";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { ScaleLoader } from "react-spinners";

export default function Userauthentication() {
  const isuser = useSelector((state) => state.counter.ISWhiteList);
  const [getValue, setgetValue] = useState({
    userName: "",
    email: "",
    metamask_Address: "",
    password: "",
  });
  const [getLogInValue, setgetLogInValue] = useState({
    email: "",
    password: "",
  });
  const [loader, setloader] = useState(false);
  const [isAuth, setisAuth] = useState(false);
  const { open, close } = useWeb3Modal();
  const { address, isConnecting, isDisconnected } = useAccount();
  const [Loading, setLoading] = useState(false);
  const [User_Auth, setUser_Auth] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();

  const handleChange = async (e) => {
    try {
      let { name, value } = e.target;

      setgetValue({ ...getValue, [name]: value });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeLogin = async (e) => {
    try {
      let { name, value } = e.target;

      setgetLogInValue({ ...getLogInValue, [name]: value });
    } catch (error) {
      console.log(error);
    }
  };

  const registerUser = async (e) => {
    try {
      e.preventDefault();
      if (isuser == true) {
        setLoading(true);
        if (
          getValue.userName === "" ||
          getValue.email === "" ||
          getValue.password === ""
        ) {
          toast.error("Please enter Data");
          setLoading(false);
        } else {
          let res = await axios.post(`${Api_LocalURl}admin_register`, {
            userName: getValue.userName,
            email: getValue.email,
            metamask_Address: address,
            password: getValue.password,
          });

          if (res.data.success === true) {
            toast.success(res.data.msg);
            setUser_Auth(true);
            setLoading(false);
          } else {
            toast.error(res.data.msg);
            setLoading(false);
          }
        }
      } else {
        toast.error("Your Not WhiteListed!");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const UserLogIn = async (e) => {
    try {
      e.preventDefault();
      
        if (getLogInValue.email == "" || getLogInValue.password == "") {
          toast.error("Please enter Data");
        } else {
          setloader(true);
          let res = await axios.post(`${Api_LocalURl}admin_login`, {
            email: getLogInValue.email,
            password: getLogInValue.password,
          });

          // console.log("getLogInValue", getLogInValue.email === "betterlogics@gmail.com");
          if (res.data.success === true) {
            toast.success(res.data.msg);
            if (res.data.admin == true) {
              history("/Admin_Refferal_details");
            } else {
              history("/Refferal_page");
            }
            dispatch(LogIndata(res.data.data));
            dispatch(UserAuth(true));
            setloader(false);
          } else {
            toast.error(res.data.msg);
            setloader(false);
          }
        }
      
    } catch (error) {
      console.log(error);
      setloader(false);
    }
  };

  // console.log("isConnecting",isDisconnected);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const CheckWhiteListed = async (e) => {
    e.preventDefault();
    if (!address) {
      toast.error("Please Connect Wallet First");
    } else {
      setLoading(true);
      let res = await axios.post(`${Api_LocalURl}Get_WhiteList_Address`, {
        metamask_Address: address,
      });
      console.log("res", res);
      if (res.data.success == true) {
        toast.success(res.data.msg);
        dispatch(UserIsWhiteList(true));
        setisAuth(true);
        setLoading(false);
      } else {
        toast.error(res.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div className="User_Authentication">
      <div className="main">
        <input
          type="checkbox"
          id="chk"
          checked={User_Auth}
          aria-hidden="true"
        />
        <div className="signup">
          {isAuth ? (
            <>
              <form className="form_div">
                <label
                  htmlFor="chk"
                  aria-hidden="true"
                  style={{ marginTop: "2rem" }}
                >
                  SignUp
                </label>
                <input
                  type="text"
                  name="userName"
                  placeholder="User name"
                  required=""
                  onChange={handleChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  required=""
                  onChange={handleChange}
                  className=""
                />
                <input
                  type="email"
                  name="metamask_Address"
                  placeholder="Metamask Address"
                  required=""
                  value={address}
                  disabled={true}
                  onChange={handleChange}
                />

                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  required=""
                  onChange={handleChange}
                />
                <div className="btn_signup">
                  <button onClick={(e) => registerUser(e)}>
                    {Loading ? (
                      <>
                        <ScaleLoader color="#fff" />
                      </>
                    ) : (
                      "Sign up"
                    )}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <>
              <form className="form_div wallet_auth ">
                <label
                  htmlFor="chk"
                  aria-hidden="true"
                  style={{ marginTop: "2rem", fontSize: "30px" }}
                >
                  Wallet Authentication
                </label>
                <div className="d-flex justify-content-center">
                  <input
                    type="text"
                    name="MetaMaskAddres"
                    placeholder="Connect Wallet"
                    value={
                      chain?.id == undefined
                        ? "Connect Wallet"
                        : chain?.id == 1
                        ? address
                        : "Switch Network"
                    }
                    disabled={true}
                    width="80%"

                    // onChange={handleChange}
                  />
                </div>

                <div className="btn_signup">
                  <button
                    onClick={(e) => (
                      e.preventDefault(),
                      chain?.id == undefined
                        ? open()
                        : chain?.id == 1
                        ? CheckWhiteListed(e)
                        : switchNetwork?.(1)
                    )}
                  >
                    {chain?.id == undefined ? (
                      address ? (
                        <>{`${address.substring(0, 6)}...${address.substring(
                          address.length - 4
                        )}`}</>
                      ) : (
                        <>
                          <span>Connect Wallet</span>
                        </>
                      )
                    ) : chain?.id == 1 ? (
                      <>
                        {Loading ? (
                          <>
                            <ScaleLoader color="#fff" />
                          </>
                        ) : (
                          " Verify Address"
                        )}
                      </>
                    ) : (
                      <>SwitchNetwork</>
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
        <div className="login">
          <form className="form_div" onClick={() => setUser_Auth()}>
            <label
              htmlFor="chk"
              aria-hidden="true"
              style={{ marginTop: "-0rem" }}
            >
              Login
            </label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required=""
              onChange={handleChangeLogin}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              required=""
              onChange={handleChangeLogin}
            />
            <div className="mt-1 d-flex justify-content-end">
              <span
                className="forgetpassword mt-1"
                onClick={() => history("/Forgot_Password")}
              >
                {" "}
                Forgot password
              </span>
            </div>
            <div className="btn_signup  mt-3">
              <button
                onClick={(e) => UserLogIn(e)}
                style={{ border: "1px solid #fff" }}
              >
                {loader ? (
                      <>
                        <ScaleLoader color="#fff" />
                      </>
                    ) : (
                      "Login"
                    )}
                
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
