import React from 'react'
import { useSelector } from "react-redux"
import { Navigate, Outlet, useLocation } from "react-router-dom"

const PublicRoute = ({ children }) => {
    const isuser = useSelector((state) => state.counter.isAuth);
    console.log('PublicRoute', isuser)


    if (isuser) {
        return <Outlet />
    }
    return children

};

export default PublicRoute;