import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './LogInSlice'
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';



const persistConfig = {
    key: 'root',
    storage: storageSession,
    stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, counterReducer)
export const store = configureStore({
    reducer: {
        counter: persistedReducer,
        middleware: [thunk]
    },
})
export const persistor = persistStore(store)