import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { LogIndata, forgetpassworddata } from "../../Redux/LogInSlice";
import axios from "axios";
import OtpForm from "react-otp-ui";
import { Button, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { Api_LocalURl } from "../../Config";

export default function Forgot_Password() {
  const UserEmail = useSelector((state) => state.counter.mail);
  const history = useNavigate();

  const [getLogInValue, setgetLogInValue] = useState({
    email: "",
  });
  const [newpassword, setnewpassword] = useState({
    Password: "",
  });
  const [otp, setotp] = useState(0);

  const [loader, setloader] = useState(false);
  const [otp_section, setotp_section] = useState(0);

  const handleChangeLogin = async (e) => {
    try {
      let { name, value } = e.target;

      setgetLogInValue({ ...getLogInValue, [name]: value });
    } catch (error) {
      console.log(error);
    }
  };
  const dispatch = useDispatch();

  const SendOTP = async (e) => {
    try {
      console.log("getLogInValue", getLogInValue);
      e.preventDefault();
      if (getLogInValue.email == "") {
        toast.error("Please Enter Email Address !");
      } else {
        setloader(true);
        let res = await axios.post(`${Api_LocalURl}sendOTP`, {
          email: getLogInValue.email,
        });

        console.log("Res", res);
        if (res.data.success === true) {
          dispatch(forgetpassworddata(getLogInValue.email));
          toast.success(res.data.msg);
          setotp_section(1);
          //   history("/Refferal_page");
          setloader(false);
        } else {
          toast.error(res.data.msg);
          setloader(false);
        }
      }
    } catch (error) {
      console.log(error);
      setloader(false);
    }
  };

  const handleOnChange = (data) => {
    console.log("data", data.otpValue);
    setotp(data.otpValue);
  };
  const VarifyOTP = async (e) => {
    try {
      e.preventDefault();

      if (otp === 0) {
        toast.error("Please Enter OTP");
      } else {
        setloader(true);
        let res = await axios.post(`${Api_LocalURl}VarifyOTP`, {
          email: UserEmail,
          code: otp,
        });

        console.log("Res", res);
        if (res.data.success === true) {
          toast.success(res.data.msg);

          setotp_section(2);
          //   history("/Refferal_page");
          setloader(false);
        } else {
          toast.error(res.data.msg);
          setloader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (e) => {
    e.preventDefault();
    console.log("newpassword", newpassword.Password, UserEmail);
    setloader(true);
    let res = await axios.post(
      `${Api_LocalURl}forgotPassword/${UserEmail}`,
      {
        newPassword: newpassword.Password,
      }
    );

    console.log("Res", res);
    if (res.data.success === true) {
      toast.success(res.data.msg);
    
      history("/");
      setloader(false);
    } else {
      toast.error(res.data.msg);
      setloader(false);
    }

    console.log("Success:", newpassword);
  };
  const handleChangeNewpassword = async (e) => {
    try {
      let { name, value } = e.target;

      setnewpassword({ ...newpassword, [name]: value });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="User_Authentication ">
        <div className="container">
          <div className="d-flex justify-content-center">
            <div className="main1  mt-n4 d-flex justify-content-center ">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-12 col-md-offset-4">
                  <div className="panel panel-default">
                    <div className="panel-body">
                      <div className="text-center">
                        <h3>
                          <i className="fa fa-lock fa-4x icons " />
                        </h3>
                        <h2 className="text-center text-white">
                          Forgot Password?
                        </h2>
                        <p className="text-white">
                          You can reset your password here.
                        </p>
                        <div className="panel-body">
                          <form
                            id="register-form"
                            role="form"
                            autoComplete="off"
                            className="form"
                            method="post"
                          >
                            {otp_section == 1 ? (
                              <>
                                <div className="container">
                                  <OtpForm
                                    onChange={handleOnChange}
                                    numberOfInputs={6}
                                    secureInput={false}
                                  />
                                </div>
                              </>
                            ) : otp_section == 0 ? (
                              <>
                                <div className="form-group">
                                  <div className="input-group">
                                    <span className="input-group-addon">
                                      <i className="glyphicon glyphicon-envelope color-blue" />
                                    </span>
                                    <input
                                      id="email"
                                      name="email"
                                      placeholder="Email address"
                                      className=" input_foregt"
                                      type="email"
                                      onChange={handleChangeLogin}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="">
                                  <Form>
                                    <Form.Item
                                      name="password"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please input your password!",
                                        },
                                      ]}
                                      hasFeedback
                                    >
                                      <Input.Password
                                        placeholder="New Password"
                                        name="Password"
                                        onChange={handleChangeNewpassword}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      name="newpassword"
                                      dependencies={["password"]}
                                      hasFeedback
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please confirm your password!",
                                        },
                                        ({ getFieldValue }) => ({
                                          validator(_, value) {
                                            if (
                                              !value ||
                                              getFieldValue("password") ===
                                                value
                                            ) {
                                              return Promise.resolve();
                                            }
                                            return Promise.reject(
                                              new Error(
                                                "The new password that you entered do not match!"
                                              )
                                            );
                                          },
                                        }),
                                      ]}
                                    >
                                      <Input.Password placeholder="Varify New Password" />
                                    </Form.Item>

                                    <Form.Item>
                                      <Button
                                        htmlType="submit"
                                        className="submit_button"
                                        onClick={(e) => onFinish(e)}
                                      >
                                        Submit
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                              </>
                            )}
                            {otp_section === 2 ? (
                              <></>
                            ) : (
                              <>
                                <div className="form-group">
                                  <button
                                    name="recover-submit"
                                    className="submit_button mt-4"
                                    onClick={(e) =>
                                      otp_section == 1
                                        ? VarifyOTP(e)
                                        : SendOTP(e)
                                    }
                                  >
                                    {otp_section == 1
                                      ? "Varify OTP"
                                      : " Send OTP"}
                                  </button>
                                </div>
                              </>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
