
import { Toaster } from 'react-hot-toast';
import './App.css';
import Userauthentication from './Components/Userauthentication/Userauthentication';
import Refferal_page from './Components/Refferal_page/Refferal_page';
import { Route, Routes } from 'react-router-dom';
import Admin_Refferal_details from './Components/Admin_Refferal_details/Admin_Refferal_details';
import Header from './Components/Header/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Forgot_Password from './Components/Userauthentication/Forgot_Password';
import PrivateRoutes from './utils/PrivateRoutes';
import PublicRoute from './utils/PublicRoute';
import { useState } from 'react';
import WalletAddress_authenitication from './Components/Userauthentication/WalletAddress_authenitication';

function App() {
  const [url, seturl] = useState("")

 let  nIntervId =setInterval(() => {
    
   let url1 = window.location
   url1 = url1.pathname
   seturl(url1)
  }, 100);

  // clearInterval(nIntervId);

  return (
    <div className="App">
      <Toaster />
      {
        url == "/" ? <> </> : url == "/Forgot_Password" ? <> </> : <><Header /> </>
      }

      <Routes>
        {/* <Route path="/" element={<PublicRoute><WalletAddress_authenitication /> </PublicRoute>} /> */}

        <Route path="/" element={<PublicRoute><Userauthentication /> </PublicRoute>} />
        <Route element={<PrivateRoutes />}>
          <Route path="/Refferal_page" element={<Refferal_page />} />
          <Route path="/Admin_Refferal_details" element={<Admin_Refferal_details />} />

        </Route>
        <Route path="/Forgot_Password" element={<PublicRoute><Forgot_Password />  </PublicRoute>} />
      </Routes>



    </div>
  );
}

export default App;
