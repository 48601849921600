import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  mail:"",
  isAuth:false,
  ISWhiteList:false
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
  
    LogIndata: (state, action) => {
      state.data = action.payload
    },
    forgetpassworddata: (state, action) => {
      state.mail = action.payload
    },
    UserAuth: (state, action) => {
      state.isAuth = action.payload
    },
    UserIsWhiteList: (state, action) => {
      state.ISWhiteList = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {  LogIndata,forgetpassworddata,UserAuth,UserIsWhiteList } = counterSlice.actions

export default counterSlice.reducer