import React from "react";
import "./Header_style.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../Redux/LogInSlice";

export default function Header() {
  const dispatch = useDispatch();
  const history = useNavigate();

  return (
    <div>
      <nav class="navbar navbar-expand-lg nav_bg">
        <div class="container">
          <a class="navbar-brand" href="#">
            <img src="./logo.png" alt="" />
          </a>
          <button className="logout_btn respn" onClick={()=>(dispatch(UserAuth(false),history("/")))}>LogOut</button>

          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link activedApps " aria-current="page" href="#">
                Affiliate Marketing dApp
                </a>
              </li>
             
            </ul>
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                
               <button className="logout_btn" onClick={()=>(dispatch(UserAuth(false),history("/")))}>LogOut</button>
                
              </li>
             
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
